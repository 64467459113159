import React from 'react';
import {range} from 'lodash';

import Layout from "../components/layout.jsx"


export default function Posts(props) {
  return (
    <Layout location={props.location}>
      <article className='page'>
        <header>{range(1, 7).map(i => {const El = `h${i}`;return (<El>Header {i}</El>)})}</header>
        <div className='sw'>{range(10).map(j => range(10).map(i => <div className={`g${i} t${j}`} >{i}</div>))}</div>
      </article>
    </Layout>
  )
}
